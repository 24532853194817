<template>
  <span class="LogoImage">
    <a :href="route">
      <img
        alt="COM-IN CRM v2"
        :src="logo"
      >
    </a>
  </span>
</template>
<script setup>
import logo from '@/images/logo.png'
import { computed } from 'vue'
import { path } from '@/js/request.js'
import { HOME } from 'routes'

const route = computed(() => path(HOME))

</script>
