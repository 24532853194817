<template>
  <SuiModal
    v-model="showModal"
    :closable="true"
  >
    <SuiModalHeader>Shortcut {{ shortcutId ? "bearbeiten" : "erstellen" }}</SuiModalHeader>
    <SuiModalContent>
      <SuiSegment>
        <ErrorMessage :error="errorMessages" />
        <SuiDimmer
          :active="anyLoading"
          inverted
        >
          <SuiLoader :active="anyLoading" />
        </SuiDimmer>
        <SuiForm>
          <SuiFormField
            v-model="label"
            label="Beschriftung"
          />
          <label class="ShortcutEditDialog-label-with-hint">Beschreibung</label>
          <div class="ui pointing below label">
            Verfügbare dynamische Variablen: $PROJECT-NUMBER, $PROJECT-NAME
          </div>
          <textarea
            v-model="description"
            rows="8"
          />
          <DynamicTopicForm
            v-model:initial-topic-id="topic"
            v-model:initial-subtopic-id="subtopic"
            :user-id="props.userId"
            @topic-changed="topicChanged"
            @subtopic-changed="subtopicChanged"
          />
          <label>Aufgabe für</label>
          <SuiDropdown
            v-model="taskReceiver"
            :options="taskReceiverOptions"
            search
            selection
            clearable
            placeholder="--KEINE AUFGABE ERSTELLEN--"
          />
          <label>Bearbeitungsstatus</label>
          <SuiDropdown
            v-model="taskStatus"
            :options="taskStatusOptions"
            search
            selection
          />
        </SuiForm>
      </SuiSegment>
    </SuiModalContent>
    <SuiModalActions>
      <SuiButton
        v-if="shortcutId"
        negative
        icon="trash alternate outline"
        :disabled="anyLoading"
        :loading="deleteLoading"
        @click="onDelete"
      >
        Löschen
      </SuiButton>
      <SuiButton
        color="green"
        :disabled="anyLoading"
        :loading="updateLoading || createLoading"
        @click="onSave"
      >
        <SuiIcon name="save" />
        Speichern
      </SuiButton>
      <SuiButton
        color="grey"
        floated="left"
        basic
        @click="closeModal"
      >
        Abbrechen
      </SuiButton>
    </SuiModalActions>
  </SuiModal>
</template>
<script setup>
import {
  SuiForm,
  SuiFormField,
  SuiDropdown,
  SuiButton,
  SuiModalHeader,
  SuiModalContent,
  SuiModalActions,
  SuiModal,
  SuiSegment,
  SuiDimmer,
  SuiLoader,
  SuiIcon
} from 'vue-fomantic-ui'
import { computed, ref, watch } from 'vue'
import DynamicTopicForm from '@/components/History/DynamicTopicForm.vue'
import { useMutation, useQuery } from '@vue/apollo-composable'
import UpdateShortcut from '@/graphql/mutations/updateShortcut.js'
import CreateOwnShortcut from '@/graphql/mutations/createOwnShortcut.js'
import { getIdFromIri, errors } from '@/js/utils/graphQL.js'
import GetTaskReceivers from '@/graphql/queries/getTaskReceivers.js'
import DeleteOwnShortcut from '@/graphql/mutations/deleteOwnShortcut.js'
import ErrorMessage from '../_partials/Atom/ErrorMessage.vue'

const props = defineProps({
  userId: {
    type: Number,
    required: true
  }
})

const shortcutModel = defineModel({ type: Object })

watch(shortcutModel, (newModel) => {
  if (newModel === null) {
    showModal.value = false

    return
  }

  showModal.value = true
  shortcutId.value = newModel.id
  label.value = newModel.label
  description.value = newModel.description
  topic.value = getIdFromIri(newModel.topic?.id)
  subtopic.value = getIdFromIri(newModel.subtopic?.id)
  taskStatus.value = taskStatusOptions.value.find(item => item.value === newModel.taskStatus)
  if (!taskStatus.value) {
    taskStatus.value = taskStatusOptions.value.find(item => item.value === 'new')
  }

  taskReceiver.value = taskReceiverOptions.value.find(item => item.type === 'users'
    ? newModel.taskRecipientUser?.id === item.value
    : newModel.taskRecipientGroup?.id === item.value
  )
})

const emit = defineEmits(['closeModal'])

const showModal = ref(false)
const taskStatusOptions = ref([
  { value: 'new', text: 'Neu' },
  { value: 'in_progress', text: 'In Bearbeitung' },
  { value: 'done', text: 'Erledigt' },
  { value: 'due_date', text: 'Warten bis Datum' },
  { value: 'not_possible', text: 'Nicht möglich' },
])
const taskReceiverOptions = ref([])

const shortcutId = ref(null)
const label = ref('')
const description = ref('')
const topic = ref(null)
const subtopic = ref(null)
const taskStatus = ref(null)
const taskReceiver = ref(null)
const error = ref(null)

const { result: taskReceivers } = useQuery(GetTaskReceivers)
const { mutate: updateShortcut, loading: updateLoading, error: updateError } = useMutation(UpdateShortcut)
const { mutate: createShortcut, loading: createLoading, error: createError } = useMutation(CreateOwnShortcut)
const { mutate: deleteShortcut, loading: deleteLoading, error: deleteError } = useMutation(DeleteOwnShortcut)

watch(taskReceivers, (newTaskReceivers) => {
  const taskReceivers = []

  newTaskReceivers.taskReceivers.forEach((item) => {
    taskReceivers.push({
      value: item.id,
      text: item.name,
      type: item.type,
      disabled: item.disabled
    })
  })

  taskReceiverOptions.value = taskReceivers
})

const anyLoading = computed(() => {
  return deleteLoading.value || updateLoading.value || createLoading.value
})

const errorMessages = computed(() => {
  return errors(updateError.value, createError.value, deleteError.value)
})

async function onSave () {
  try {
    error.value = null
    await executeGraphQlRequest()
    closeModal()
  } catch (error) {
    error.value = error
  }
}

async function onDelete () {
  try {
    error.value = null
    await deleteShortcut({
      id: shortcutId.value
    })
    closeModal()
  } catch (error) {
    error.value = error
  }
}

async function executeGraphQlRequest () {
  const taskRecipientUser = taskReceiver.value && taskReceiver.value.type === 'users'
    ? taskReceiver.value.value
    : null
  const taskRecipientGroup = taskReceiver.value && taskReceiver.value.type === 'groups'
    ? taskReceiver.value.value
    : null

  if (shortcutId.value) {
    await updateShortcut({
      id: shortcutId.value,
      label: label.value,
      description: description.value,
      taskStatus: taskStatus.value.value,
      topicId: topic.value ? '/api/topics/' + topic.value : null,
      subtopicId: subtopic.value ? '/api/subtopics/' + subtopic.value : null,
      taskRecipientUser,
      taskRecipientGroup
    })
  } else {
    await createShortcut({
      label: label.value,
      description: description.value,
      taskStatus: taskStatus.value.value,
      topicId: topic.value ? '/api/topics/' + topic.value : null,
      subtopicId: subtopic.value ? '/api/subtopics/' + subtopic.value : null,
      taskRecipientUser,
      taskRecipientGroup
    })
  }
}

function closeModal () {
  showModal.value = false
  emit('closeModal')
}

function topicChanged (topicId) {
  topic.value = topicId
}

function subtopicChanged (subtopicId) {
  subtopic.value = subtopicId
}
</script>
