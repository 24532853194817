<template>
  <div
    :class="{
      'QRSnapshoter': true,
      'QRSnapshoter--scanning': videoVisible,
      'QRSnapshoter--result': finalResult !== null,
      'QRSnapshoter--loading': loading,
      'QRSnapshoter--aspect-horizontal': aspectRatio === null || aspectRatio <= 1,
      'QRSnapshoter--aspect-vertical': aspectRatio !== null && aspectRatio > 1,
    }"
    :style="cssVariables"
  >
    <div>
      <div class="image QRSnapshoter-video">
        <div
          v-if="error !== null"
          class="content"
        >
          {{ error.message }}
        </div>
        <div
          v-else-if="messages.length > 0 && !loading"
          class="content"
        >
          <div class="ui stackable grid">
            <div class="ten wide column">
              <CheckList :messages="messages" />
            </div>
            <div class="six wide column">
              <div>
                <CameraChooser
                  v-if="videoVisible"
                  @camera-selected="cameraSelected"
                />
                <SuiButton
                  v-else-if="finalResult !== null"
                  icon="redo"
                  labeled
                  fluid
                  class="QRSnapshoter-redo"
                  @click="() => reset()"
                >
                  Neue Aufnahme
                </SuiButton>
              </div>
            </div>
          </div>
        </div>

        <div class="image QRSnapshoter-video-scale">
          <div class="image QRSnapshoter-video-container">
            <SuiProgress
              v-if="finalResult === null && (loading || finalizationStarted)"
              color="green"
              indeterminate="swinging"
              progress
            >
              {{ finalizationStarted ? 'Foto wird erzeugt' : 'Lade' }}
            </SuiProgress>
            <div class="image QRSnapshoter-video-scanner">
              <video ref="video" />
              <div
                ref="codeOverlay"
                class="QRSnapshoter-video-scanner-overlay"
              />
            </div>
            <div
              v-if="finalResult !== null"
              class="image QRSnapshoter-video-result"
            >
              <img
                alt=""
                :src="finalResult.picture"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import QrScanner from 'qr-scanner'
import {
  sharpness,
  rectangularity,
  centeredness,
  shoelaceArea,
  trapezoidBoundingBoxRotation,
  proximityTo
} from '@/js/utils/imageUtil'
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import CheckList from '@/components/_partials/Molecule/CheckList.ce.vue'
import { SuiProgress, SuiButton } from 'vue-fomantic-ui'
import CameraChooser from '@/stories/molecule/CameraChooser.vue'

const props = defineProps({
  overlay: {
    type: Boolean,
    required: false,
    default: true
  },
  wait: {
    type: Number,
    required: false,
    default: 3000
  },
  validate: {
    type: Function,
    required: false,
    default: null
  },
  quality: {
    type: Function,
    required: false,
    default: null
  }
})

const emit = defineEmits(['error', 'scanning', 'result'])
const videoVisible = ref(false)
const bestResult = ref(null)
const finalResult = ref(null)
const validation = ref(doValidation(null))
const loading = ref(true)
const error = ref(null)
const video = ref(null)
const finalizationStarted = ref(false)
const timeout = ref(null)
const qrScanner = ref(null)
const aspectRatio = ref(null)
const codeOverlay = ref(null)

function getCanvas (video) {
  const canvas = document.createElement('canvas')
  canvas.width = video.videoWidth
  canvas.height = video.videoHeight
  const canvasContext = canvas.getContext('2d')
  canvasContext.drawImage(video, 0, 0)
  return canvas
}

function getImage (canvas) {
  return canvas.toDataURL('image/png')
}

function calculateQuality (result) {
  if (props.quality !== null) {
    return props.quality(result)
  }

  return result.sharpness +
      (result.rectangularity * 100) +
      (result.straightness * 100) +
      (result.centeredness * 50)
}

function doValidation (data) {
  if (props.validate !== null) {
    const result = props.validate(data)
    if (result === undefined) {
      return false
    }
    return result
  }

  return true
}

function updateValidation (data) {
  validation.value = doValidation(data)
}

function updateAspectRatio (width = 0, height = 0) {
  width = width > 0 ? width : video.value.videoWidth
  height = height > 0 ? height : video.value.videoHeight

  if (width > 0 && height > 0) {
    aspectRatio.value = width / height
  }
}

function isValid (result) {
  if (typeof result === 'boolean') {
    return result
  } else {
    let valid = true
    Object.values(result).forEach((item) => {
      valid = valid && item
    })
    return valid
  }
}

function delayed (startFinalization = false) {
  return new Promise((resolve) => {
    if (startFinalization) {
      finalizationStarted.value = true
    }

    if (timeout.value !== null) {
      clearTimeout(timeout.value)
    }
    timeout.value = setTimeout(() => {
      resolve()
    }, props.wait)
  })
}

function onScanned (result) {
  if (finalResult.value !== null) {
    return
  }

  if (result.data.length === 0) {
    updateValidation(null)
  } else {
    let canvas
    if ('canvas' in result) {
      canvas = result.canvas
    } else {
      canvas = getCanvas(video.value)
    }

    result.sharpness = sharpness(canvas)
    result.picture = getImage(canvas)
    result.width = canvas.width
    result.height = canvas.height

    result.straightness = 1 - proximityTo(trapezoidBoundingBoxRotation(result.cornerPoints), 90)
    result.rectangularity = rectangularity(result.cornerPoints)
    result.centeredness = centeredness(canvas.width, canvas.height, result.cornerPoints)
    result.qrSize = shoelaceArea(result.cornerPoints)
    result.percentOfImage = result.qrSize / (canvas.width * canvas.height)
    result.quality = calculateQuality(result)

    updateAspectRatio(canvas.width, canvas.height)
    updateValidation(result)
    const validated = isValid(validation.value)

    if (validated) {
      if (bestResult.value === null || result.quality > bestResult.value.quality) {
        bestResult.value = result
        return delayed(true).then(async () => {
          updateValidation(result)
          finalResult.value = result
          await stopScanning()
          emit('result', result)
        })
      }
    } else if (!finalizationStarted.value) {
      bestResult.value = null
      return delayed().then(() => updateValidation(null))
    }
  }
}

async function reset () {
  updateValidation(null)
  emit('result', null)
  finalizationStarted.value = false
  bestResult.value = null
  finalResult.value = null
  await startScanning()
}

const messages = computed(() => {
  const items = []

  if (typeof validation.value === 'boolean') {
    items.push({
      text: 'Gültiger QR Code',
      valid: validation.value
    })
  } else {
    for (const [text, valid] of Object.entries(validation.value)) {
      items.push({
        text,
        valid
      })
    }
  }

  return items
})

const cssVariables = computed(() => {
  const vars = {}

  if (aspectRatio.value !== null) {
    const aspectTop = (1 / aspectRatio.value) * 100
    vars['--aspect-padding-top'] = aspectTop + '%'
    vars['--max-width'] = 'calc((100vh - var(--vertical-space)) / ' + (aspectTop / 100) + ')'
  }

  return vars
})

function setError (code, e) {
  let message = ''

  switch (code) {
    case 'PERMISSON_DENIED':
      message = 'Berechtigungen verwehrt'
      break
    case 'UNKNOWN':
      message = 'Unbekannter Fehler'
      break
  }

  const info = {
    code,
    message
  }

  loading.value = false
  error.value = info

  emit('error', info)
}

async function startScanning () {
  try {
    await qrScanner.value.start()
    updateAspectRatio()
    loading.value = false
    videoVisible.value = true
    emit('scanning', true)
    return true
  } catch (e) {
    try {
      await QrScanner.hasCamera()
      setError('PERMISSON_DENIED', e)
    } catch {
      setError('UNKNOWN', e)
    }
    return false
  }
}

function stopScanning () {
  if (qrScanner.value !== null) {
    qrScanner.value.stop()
  }
  videoVisible.value = false
  emit('scanning', false)
  return Promise.resolve()
}

onBeforeUnmount(async () => {
  await stopScanning()
})

async function cameraSelected (cameraId) {
  await qrScanner.value.setCamera(cameraId)
}

onMounted(async () => {
  if (video.value === null) {
    return
  }

  const calculateScanRegion = () => {
    return (video) => {
      return {
        x: 0,
        y: 0,
        width: video.videoWidth,
        height: video.videoHeight,
        downScaledWidth: video.videoWidth, // size of image to scan
        downScaledHeight: video.videoHeight
      }
    }
  }

  qrScanner.value = new QrScanner(
    video.value,
    result => onScanned(result),
    {
      returnDetailedScanResult: true,
      highlightScanRegion: false,
      calculateScanRegion,
      highlightCodeOutline: true,
      overlay: codeOverlay.value
    }
  )

  if (!props.overlay) {
    await startScanning()
  }
})
</script>
