import BradySdk from '@bradycorporation/brady-web-sdk'
import QRCode from 'qrcode'
import FontUtil from '@/js/utils/fonts.js'

export default class HomeIdPrint {
  static isOverridden = false

  constructor (callback = null) {
    if (callback === null) {
      callback = () => {}
    }

    this.callback = callback

    this.sdk = new BradySdk((data) => {
      this.printerUpdatesCallback(data)
    }, false)
  }

  printerUpdatesCallback (data) {
    this.callback(data)
  }

  isSupportedBrowser () {
    return this.sdk.isSupportedBrowser()
  }

  createQR (text, qr = null) {
    const fontFamily = 'Roboto Mono'
    if (qr === null) {
      qr = text
    }

    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas')
      canvas.width = 500
      canvas.height = 120
      const context = canvas.getContext('2d')

      context.fillStyle = 'black'

      const drawOnCanvas = async () => {
        try {
          await FontUtil.untilLoaded(fontFamily)
          let fontSize = canvas.height
          context.font = `bold ${fontSize}px ${fontFamily}`

          let textWidth = context.measureText(text).width

          const qrWidth = 102
          while (textWidth > canvas.width - qrWidth || fontSize > canvas.height) {
            fontSize -= 1
            context.font = `bold ${fontSize}px ${fontFamily}`
            textWidth = context.measureText(text).width
          }

          const x = qrWidth + (canvas.width - qrWidth - textWidth) / 2

          const metrics = context.measureText(text)
          const actualHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent
          const y = (canvas.height + actualHeight) / 2

          context.clearRect(0, 0, canvas.width, canvas.height)

          QRCode.toCanvas(document.createElement('canvas'), qr, {
            width: qrWidth - 1, // QR code size
            margin: 2 // margin for QR code, needed to keep a white border around the QR code
          }, (err, qrCanvas) => {
            if (err) {
              return reject(new Error('QR Code generation failed'))
            }
            context.drawImage(qrCanvas, 0, 5)
            context.fillText(text, x, y)
          })
          resolve(canvas.toDataURL('image/png'))
        } catch (error) {
          reject(error)
        }
      }
      drawOnCanvas()
    })
  }

  overrideRequestDevice () {
    const oldRequest = navigator.bluetooth.requestDevice
    if (!HomeIdPrint.isOverridden) {
      navigator.bluetooth.requestDevice = async (filter) => {
        return await this.setDevice(oldRequest, filter)
      }
      HomeIdPrint.isOverridden = true
    }
  }

  connect () {
    return new Promise((resolve, reject) => {
      const connectPrinter = async () => {
        this.overrideRequestDevice()
        let couldConnect = this.sdk.isConnected()

        if (!couldConnect) {
          couldConnect = await this.sdk.showDiscoveredBleDevices()
        }

        if (this.sdk.isConnected() && couldConnect) {
          this.printerUpdatesCallback(null)
          resolve()
        } else {
          reject(new Error('Failed to connect Bluetooth printer'))
        }
      }
      connectPrinter()
    })
  }

  async setDevice (oldRequest, filter) {
    const existing = await navigator.bluetooth.getDevices()
    for (const device of existing) {
      if (device.name.includes('M511') || device.name.includes('M211')) {
        try {
          await device.gatt.connect()

          if (device.gatt.connected) {
            return device
          }
        } catch (error) {
          console.log(`Failed to connect to ${device.name}: ${error.message}`)
        }
      }
    }

    return oldRequest.call(navigator.bluetooth, filter)
  }

  print (dataUri) {
    return new Promise((resolve, reject) => {
      (async () => {
        if (!this.sdk.isConnected()) {
          await this.connect()
        }

        const imageToPrint = new Image()

        try {
          imageToPrint.src = dataUri
        } catch (error) {
          reject(error)
        }

        const sdk = this.sdk

        imageToPrint.onload = async () => {
          const printingStatus = await sdk.printBitmap(imageToPrint)
          if (printingStatus) {
            resolve('Printed Successfully!')
          } else {
            reject(new Error('Failed to print...'))
          }
        }
      })()
    })
  }
}
