<template>
  <div class="AccordionTable">
    <slot
      v-if="menu.length > 0"
      name="menu"
    >
      <div class="ui grid compact">
        <div class="column AccordionTable-menu-left">
          <SuiMenu secondary>
            <SuiMenuItem as="div">
              <SuiButton
                v-for="(item, index) in menu"
                :key="index"
                as="a"
                :href="item.url"
                primary
              >
                <SuiIcon
                  v-if="'icon' in item"
                  v-bind="item.icon"
                />
                {{ item.title }}
              </SuiButton>
            </SuiMenuItem>
          </SuiMenu>
        </div>
        <div class="column AccordionTable-menu-right">
          <SuiMenu secondary>
            <slot name="menu_middle" />
            <SuiMenuItem
              v-if="showCollapseAll"
              as="div"
            >
              <SuiButton
                primary
                type="button"
                :disabled="isLoading"
                @click="toggleCollapseAll()"
              >
                <SuiIcon :name="active.length > 0 ? 'angle up' : 'angle down'" />
                {{ active.length > 0 ? 'Details einklappen' : 'Details ausklappen' }}
              </SuiButton>
            </SuiMenuItem>
          </SuiMenu>
        </div>
      </div>
    </slot>
    <div
      v-if="$slots['header-right']"
      class="header-button"
    >
      <slot
        name="header-right"
      />
    </div>
    <table class="ui celled striped table">
      <thead>
        <tr>
          <slot
            v-for="(item, index) in headers"
            :key="index"
            :item="item"
            name="header"
          >
            <th>
              {{ item }}
            </th>
          </slot>
        </tr>
      </thead>
      <tbody v-if="isLoading">
        <tr
          v-for="placeholderIndex in Array(4)"
          :key="placeholderIndex"
        >
          <td
            v-for="index in headers"
            :key="index"
          >
            <div class="ui fluid placeholder">
              <div class="paragraph">
                <div class="line" />
              </div>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <template
          v-for="(item, index) in items"
          :key="index"
        >
          <tr @click="collapsible ? toggleActive(this, index) : null">
            <slot
              name="row"
              :item="item"
            >
              <td
                v-for="(tdItem, tdIndex) in item"
                :key="tdIndex"
              >
                {{ tdItem }}
              </td>
            </slot>
          </tr>
          <tr v-if="active.indexOf(index) > -1">
            <td :colspan="headers.length">
              <slot
                name="detail"
                :item="item"
              >
                {{ item }}
              </slot>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>
<script>
import { SuiButton, SuiIcon, SuiMenu, SuiMenuItem } from 'vue-fomantic-ui'

export default {
  components: {
    SuiMenuItem,
    SuiMenu,
    SuiButton,
    SuiIcon
  },
  props: {
    headers: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    collapsed: {
      type: Boolean,
      default: true
    },
    collapsible: {
      type: Boolean,
      default: true
    },
    showCollapseAll: {
      type: Boolean,
      default: true
    },
    menu: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: null
    }
  },
  data: () => ({
    initialized: false,
    active: []
  }),
  computed: {
    isLoading () {
      if (this.loading !== null) {
        return this.loading
      }

      return this.items === null
    }
  },
  updated () {
    if (this.isLoading) {
      this.initialized = false
      this.active = []
      return
    }

    if (!this.initialized &&
        this.collapsed === false) {
      this.toggleCollapseAll()
    }

    this.initialized = true
  },
  methods: {
    toggleCollapseAll () {
      if (this.active.length > 0) {
        this.active = []
      } else {
        this.active = [...this.items.keys()]
      }
    },
    toggleActive (e, nr) {
      if (e.target instanceof HTMLAnchorElement ||
          e.target instanceof HTMLInputElement ||
          e.target instanceof HTMLButtonElement) {
        return
      }

      if (this.active.indexOf(nr) > -1) {
        this.active = this.active.filter((item) => item !== nr)
      } else {
        this.active.push(nr)
      }
    }
  }
}
</script>
