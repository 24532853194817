<template>
  <div>
    <ErrorMessage :error="error" />
    <EntityLink
      v-if="showSelectedBuilding && selectedBuilding && selectedBuilding.id"
      label="Gebäude"
      :reference="selectedBuilding.id.toString()"
      :info="selectedBuilding.street + ' ' + selectedBuilding.house_number"
      :attr="{href: '/gebaeudeDetails/' + selectedBuilding.id}"
    />
    <br v-if="selectedBuilding && selectedBuilding.id">
    <SuiButton
      v-if="!initSelectedBuildingId || canChangeSelectedBuilding"
      id="building-search-modal_open-button"
      primary
      class="m-t-10"
      :disabled="isDisabled"
      @click.prevent="openModal"
    >
      <SuiIcon :name="modalButtonIcon" />
      {{ modalButton }}
    </SuiButton>
    <BuildingAddressBook
      :show-modal="showModal"
      :init-search-url="initSearchUrl"
      :init-confirm-text="initConfirmText"
      :init-confirm-button-color="initConfirmButtonColor"
      :init-confirm-button-icon="initConfirmButtonIcon"
      :init-callback="initCallback"
      :init-search="initSearch"
      @close-modal="closeModal"
      @building-selected="setSelectedBuilding"
    />
  </div>
</template>

<script setup>
import { SuiButton, SuiIcon } from 'vue-fomantic-ui'
import { ref } from 'vue'
import EntityLink from '@/stories/molecule/EntityLink.ce.vue'
import { request } from '@/js/request.js'
import BuildingAddressBook from '@/components/Building/BuildingAddressBook.vue'
import { API_V1_BUILDING_INFO_GET } from 'routes'
import ErrorMessage from '@/components/_partials/Atom/ErrorMessage.vue'

const props = defineProps({
  modalButton: {
    type: String,
    default: 'Gebäude auswählen'
  },
  modalButtonIcon: {
    type: String,
    default: 'home'
  },
  initConfirmText: {
    type: String,
    default: ''
  },
  initConfirmButtonColor: {
    type: String,
    default: 'primary'
  },
  initConfirmButtonIcon: {
    type: String,
    default: ''
  },
  initSearch: {
    type: String,
    default: ''
  },
  isDisabled: {
    type: Boolean,
    default: false
  },
  showSelectedBuilding: {
    type: Boolean,
    default: true
  },
  initSearchUrl: {
    type: String,
    default: '/api/v1/building/search/'
  },
  initCallback: {
    type: String,
    default: null
  },
  initSelectedBuildingId: {
    type: Number,
    default: null
  },
  canChangeSelectedBuilding: {
    type: Boolean,
    default: false
  },
  resultElementName: {
    type: String,
    default: null
  }
})

const error = ref(null)
const showModal = ref(false)
const selectedBuilding = ref(null)

if (props.initSelectedBuildingId !== null) {
  request({
    route: API_V1_BUILDING_INFO_GET,
    params: {
      building: props.initSelectedBuildingId
    }
  }).then(({ json }) => {
    selectedBuilding.value = json
  }).catch((e) => {
    error.value = e
  })
}

function openModal () {
  showModal.value = true
}

function closeModal () {
  showModal.value = false
}

function setSelectedBuilding (building) {
  selectedBuilding.value = building

  if (props.resultElementName) {
    const resultElement = document.querySelector('[name="' + props.resultElementName + '"]')
    resultElement.value = selectedBuilding.value.id

    if (resultElement.tagName.toLowerCase() === 'input' && resultElement.type.toLowerCase() === 'hidden') {
      // browser is not triggering a change event for hidden fields, so we need to do it, to be able to react on
      // changes. Used by the create Switch form.
      resultElement.dispatchEvent(new Event('change', {
        bubbles: true,
        cancelable: true
      }))
    }
  }
}
</script>
