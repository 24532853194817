<template>
  <div class="PopupHeader">
    <div class="PopupHeader-logo">
      <LogoImage />
    </div>
    <div class="PopupHeader-header">
      <SuiHeader as="h1">
        <span class="PopupHeader-title">{{ title }}</span>
        <span class="PopupHeader-link">
          <slot name="link">
            <EntityLink
              v-if="entityLink"
              v-bind="{
                ...entityLink,
                info: null
              }"
            />
          </slot>
        </span>
        <SuiHeaderSubheader class="PopupHeader-subheader">
          {{ subTitle }}
        </SuiHeaderSubheader>
      </SuiHeader>
    </div>
    <div class="PopupHeader-action">
      <SuiButton
        v-if="back === null"
        icon="window close outline"
        primary
        @click="onClose"
      >
        Fenster schließen
      </SuiButton>
      <SuiButton
        v-else
        icon="back"
        @click="onBack"
      >
        Zurück
      </SuiButton>
    </div>
  </div>
</template>
<script setup>
import { SuiButton, SuiHeader, SuiHeaderSubheader } from 'vue-fomantic-ui'
import LogoImage from '../atom/LogoImage.vue'
import EntityLink from './EntityLink.ce.vue'

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  subTitle: {
    type: String,
    default: ''
  },
  back: {
    type: String,
    default: null
  },
  entityLink: {
    type: Object,
    default: null
  }
})

function onClose () {
  window.close()
}

function onBack () {
  location.href = props.back
}
</script>
