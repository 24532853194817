<template>
  <SuiModal
    :closable="false"
    :model-value="model.show"
    @update:model-value="(show) => updateModel({show})"
  >
    <SuiModalHeader>
      <span class="ModalWizard-header-main">{{ headerPrefix }}</span>
      <span class="ModalWizard-header-separator"> | </span>
      <span v-if="currentStepIndex < steps.length - 1">Schritt {{ currentStepIndex + 1 }} von {{ steps.length }}: </span>
      <span>{{ headerLabel() }}</span>
    </SuiModalHeader>
    <SuiModalContent :class="{'ModalWizard-content': true, 'ModalWizard-content--loading': model.loading !== null && model.loading !== undefined }">
      <StepGroup
        size="mini"
        :unstackable="true"
      >
        <Step
          v-for="(step, index) in steps"
          :key="index"
          :completed="index < currentStepIndex"
          :active="index === currentStepIndex"
          :disabled="index > currentStepIndex"
          :icon="step.icon"
          :title="step.title"
        />
      </StepGroup>
      <SuiModalDescription>
        <span
          v-if="model.error"
          class="ui error text"
        >
          {{ model.error }}
        </span>
        <slot
          v-if="model.show"
          :name="currentSlot"
        />
      </SuiModalDescription>
    </SuiModalContent>
    <SuiModalActions class="ModalWizard-actions">
      <div class="ui grid">
        <div
          :class="{
            'three': !props.previousDisabled,
            'two': props.previousDisabled,
            'column': true,
            'row': true
          }"
        >
          <div
            v-if="!props.previousDisabled"
            class="column ModalWizard-actions-left"
          >
            <SuiButton
              v-if="!isPreviousDisabled"
              primary
              :loading="model.loading === 'previous'"
              :disabled="model.loading === 'previous' || isPreviousDisabled"
              @click="goBack()"
            >
              <SuiIcon name="chevron circle left" />
              Zurück
            </SuiButton>
          </div>
          <div
            :class="{
              'column': true,
              'ModalWizard-actions-middle': !props.previousDisabled,
              'ModalWizard-actions-left': props.previousDisabled,
            }"
          >
            <SuiButton
              v-if="!isLastStep"
              :loading="model.loading === 'abort'"
              :disabled="model.loading === 'abort' || isLastStep"
              color="grey"
              basic
              @click="closeWizard"
            >
              Abbrechen
            </SuiButton>
          </div>
          <div class="column ModalWizard-actions-right">
            <SuiButton
              v-if="!isLastStep"
              :loading="model.loading === 'next'"
              :disabled="model.loading === 'next' || isNextDisabled"
              class="green"
              @click="goNext()"
            >
              <span>Weiter</span>
              <SuiIcon name="chevron circle right" />
            </SuiButton>
            <SuiButton
              v-else
              class="green"
              :loading="model.loading === 'complete'"
              @click="completeWizard"
            >
              Schließen
            </SuiButton>
          </div>
        </div>
      </div>
    </SuiModalActions>
  </SuiModal>
</template>

<script setup>
import {
  SuiModal,
  SuiModalContent,
  SuiModalHeader,
  SuiModalActions,
  SuiModalDescription,
  SuiButton,
  StepGroup,
  Step,
  SuiIcon
} from 'vue-fomantic-ui'
import { computed } from 'vue'

const props = defineProps({
  steps: {
    type: Array,
    default: () => []
  },
  nextDisabled: {
    type: Boolean,
    default: false
  },
  previousDisabled: {
    type: Boolean,
    default: false
  },
  onAbort: {
    type: Function,
    default: (e) => e.resolve()
  },
  onComplete: {
    type: Function,
    default: (e) => e.resolve()
  },
  onNext: {
    type: Function,
    default: (e) => e.resolve()
  },
  onPrevious: {
    type: Function,
    default: (e) => e.resolve()
  },
  headerPrefix: {
    type: String,
    default: ''
  },
})
const model = defineModel({
  type: Object,
  default: {
    show: false,
    loading: null,
    error: null,
    step: null
  }
})

const currentStepIndex = computed(function () {
  for (let i = 0; i < props.steps.length; i++) {
    const step = props.steps[i]
    if (JSON.stringify(step) === JSON.stringify(model.value.step)) {
      return i
    }
  }

  return 0
})

const currentStep = computed(function () {
  if (typeof props.steps[currentStepIndex.value] === 'undefined') {
    return null
  }

  return props.steps[currentStepIndex.value]
})

const isLastStep = computed(function () {
  return currentStepIndex.value === props.steps.length - 1
})

const isNextDisabled = computed(function () {
  return props.nextDisabled
})

const isPreviousDisabled = computed(function () {
  return currentStepIndex.value === 0 || isLastStep.value || props.previousDisabled
})

const currentSlot = computed(function () {
  return currentStep.value?.slot
})

function updateModel (data) {
  model.value = {
    ...model.value,
    ...data
  }
}

function headerLabel () {
  return currentStep.value?.label
}

function completeWizard () {
  updateModel({
    loading: 'complete'
  })
  props.onComplete({
    step: currentStep.value,
    resolve: () => {
      updateModel({
        show: false,
        loading: null,
        error: null,
        step: null
      })
    },
    reject: (error) => fail(error)
  })
}

function closeWizard () {
  updateModel({
    loading: 'abort'
  })
  props.onAbort({
    step: currentStep.value,
    resolve: () => {
      updateModel({
        show: false,
        loading: null,
        error: null,
        step: null
      })
    },
    reject: (error) => fail(error)
  })
}

function stepNext () {
  if (model.value.loading !== 'next' && model.value.loading !== null) {
    return
  }
  const update = {
    loading: null
  }
  if (currentStepIndex.value < props.steps.length - 1) {
    update.step = props.steps[currentStepIndex.value + 1]
    update.error = null
  }

  updateModel(update)
}

function stepBack () {
  if (model.value.loading !== 'previous' && model.value.loading !== null) {
    return
  }
  const update = {
    loading: null
  }
  if (currentStepIndex.value > 0) {
    update.step = props.steps[currentStepIndex.value - 1]
    update.error = null
  }

  updateModel(update)
}

function fail (error) {
  updateModel({
    loading: null,
    error
  })
}

async function goNext () {
  updateModel({
    loading: 'next'
  })
  props.onNext({
    step: currentStep.value,
    resolve: () => stepNext(),
    reject: (e) => fail(e)
  })
}

async function goBack () {
  updateModel({
    loading: 'previous'
  })
  props.onPrevious({
    step: currentStep.value,
    resolve: () => stepBack(),
    reject: (e) => fail(e)
  })
}
</script>
