<template>
  <div>
    <div>
      <draggable
        :list="people"
        item-key="id"
        group="people"
        :delay="1000"
        :delay-on-touch-only="true"
        @change="movePersonToBuilding"
      >
        <template #item="{element: person}">
          <PersonPreviewComponent
            :key="person.id"
            :person="person"
          />
        </template>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import PersonPreviewComponent from '@/components/Building/PersonPreviewComponent.vue'
import { useBuildingStore } from '@/stores/BuildingStore'
import { mapState } from 'pinia'
import { updatePerson } from '@/js/utils/unitAssignmentUtil'

export default {
  components: {
    draggable,
    PersonPreviewComponent
  },
  props: {
    people: {
      type: Array,
      default: () => []
    }
  },
  setup () {
    const buildingStore = useBuildingStore()

    return { buildingStore }
  },
  computed: {
    ...mapState(useBuildingStore, {
      buildingData: 'buildingData'
    })
  },
  methods: {
    updatePerson,
    movePersonToBuilding (event) {
      if (event.added) {
        const { unit_id: unitId, ...person } = {
          ...event.added.element,
          building_id: this.buildingStore.getBuildingId
        }
        this.updatePerson(person, this.buildingStore)
      }
    }
  }
}
</script>
